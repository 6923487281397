@import '../../Css/variables';

.root {
    display: flex;
    flex-direction: column;
    h2,h3,p {
        text-align: center;
    }
    .secTitle {
        display: block;
        font-size: .75em;
    }
    section {
        display: flex;
        flex-direction: column;
        padding: 3em 0;
        &:nth-child(odd) {
            background: $color-light;
        }
        label {
            display: flex;
            justify-content: space-between;
            width: 300px;
            margin: auto;
        }
        h2 {
            margin-bottom: .25em;
            padding: 0 15px;
        }
        p {
            font-size: 1.1em;
            margin-top: 0;
            color: $color-dark;
            padding: 0 15px;
            @media screen and (max-width: 568px) {
                font-size: 1em;
            }
        }
        .inputField {
            @extend .container;
            margin: auto;
            padding-top: 2em;
            padding-bottom: 2em;
            input {
                border: 1px solid $color-dark;
                display: block;
                padding: 5px 10px;
                margin: auto;
                border-bottom: 3px solid $color-dark;
                &:focus, &:hover {
                    border-color: #000;
                    border-bottom-color: $color-colored;
                    outline: none;
                }
                &:valid {
                    border-bottom-color: $color-colored !important;
                }
            }
        }
        .checkboxField, .radioField {
            @extend .container;
            padding-top: 2em;
            padding-bottom: 2em;
            label {
                padding: .2em 0;
            }
        }
        .btnSubmit {
            width: fit-content;
            margin: auto;
            padding: 10px 30px;
        }
    }
    h6 {
        text-align: center;
    }
}

.results {
    .resultsHeading {
        padding: 2em 0;
        text-align: center;
        .saveResults {
            color: $color-dark;
        }
        a {
            color: $color-colored;
        }
        .scoring {
            text-align: center;
            font-size: 38px;
            font-weight: bold;
        }
        .title {
            margin-bottom: 0;
        }
        .subTitle {
            font-weight: bold;
        }
        .btnDecorated {
            margin-top: 2em;
        }
    }
    .charts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 2em 0;
        svg {
            overflow: visible;
            padding: 0 35px;
            text {
                font-size: 16px;
                @media screen and (max-width: 768px) { font-size: 14px; }
                @media screen and (max-width: 568px) { font-size: 12px; }
                @media screen and (min-width: 568px) { &:nth-child(7) { transform: translateX(-40px) } }
            }
        }
        @media screen and (max-width: 568px) {
            text-align: center;
            svg {max-width: 93%;}
        }
    }
    .storefrontResults {
        padding-bottom: 0;
        .storefrontResultWrapper {
            position: relative;
        }
        .storefrontResult {
            h2 {
                text-align: center;
                margin: 15px 0;
            }
        }
        .storefrontWrapper {
            display: flex;
            flex-direction: column;
        }
    }
            .backendResult {
            padding: 6em 0;
            &:nth-child(odd) {
                background: $color-light;
            }
            .subTitle {
                font-weight: 700;
                font-size: 46px;
                margin: 0;
                @media screen and (max-width: 768px) {
                    font-size: 32px;
                }
            }
        }
    &.render {
        width: 1920px !important;
        .backendResult {
            .container {
                max-width: 1200px !important;
                margin: auto !important;
            }
        }
        .btnDownload {
            display: none !important;
        }
    }
}

.overlay {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: $color-secondary;
    background-image: url('../../assets/epoint.png');
    background-position: 50% 35%;
    background-repeat: no-repeat;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    h2 {
        position: absolute;
        color: $color-primary;
        width: 100vw;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
    }
}