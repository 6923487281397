@import '../../Css/variables';

.containerStyles {
    width: 100%;
    max-width: 500px;
    height: 20px;
    background-color: #fff;
    border-radius: 8px;
    margin: auto;
    margin-bottom: 5px;
}

.fillerStyles {
    height: 100%;
    border-radius: 8px;
    background-color: $color-colored;
    transition: width 1s ease-in-out;
    position: relative;
    max-width: 100%;
}