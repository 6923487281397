@import '../../Css/variables';

.trusted {
    display: flex; flex-direction: column; gap: 2em;
    padding-top: 4em; padding-bottom: 4em;
    h2 {
        margin-top: 0;
    }
    img {
        display: block;
        margin: auto;
        max-width: 100%;
    }
}