@import '../../Css/variables';

.navbar {
    position: absolute;
    width: 100%;
    padding: 0 5%;
    @media screen and (max-width: 768px) {
        nav {
            display: flex;
        }
    }
    .container {
        padding: 0;
    }
    nav {
        display: flex;
        gap: 6em;
        padding: 10px 15px;
        height: 125px;
        align-items: center;
        justify-content: space-between;
        .logo {
            z-index: 1;
            .logoH {
                margin: 0;
                a {
                    text-decoration: none;
                    color: $color-colored;
                }
            }
            @media screen and (min-width: 769px) and (max-width: 992px) {
                max-width: 120px;
            }
        }
        @media screen and (max-width: 768px) {
            gap: .5em;
        }
        .navList {
            padding: 0;
            margin: 0;
            margin-left: auto;
            list-style: none;
            display: flex;
            gap: 3em;
            line-height: 2;
            align-self: center;
            li {
                &:hover .dropdownList {
                    @media screen and (min-width: 769px) {
                        max-height: 300px;
                        opacity: 1;
                    }
                }
                a {
                    text-decoration: none;
                    color: $color-light;
                    position: relative;
                    padding-bottom: 10px;
                    letter-spacing: .5px;
                    font-weight: 300;
                    font-family: 'Khand', 'Segoe UI', 'arial';
                    font-size: 21px;
                    &:hover, &:focus {
                        color: $color-colored;
                    }
                    .small {
                        text-transform: none;
                    }
                    &.dropdown {
                        position: relative;
                        padding-right: 20px;
                        &::after {
                            content: '';
                            width: 10px;
                            height: 10px;
                            background: url('../../assets/icons/down-chevron.svg');
                            background-size: cover;
                            position: absolute;
                            top: 10px;
                            right: 0;
                            transition: .3s transform;
                            @media screen and (min-width: 1200px) {
                                filter: invert(1);
                            }
                        }
                    }
                }
                .dropdownList {
                    position: absolute;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    max-height: 0;
                    overflow: hidden;
                    transition: .5s max-height opacity ease;
                    opacity: 0;
                    a {
                        cursor: pointer;
                        display: block;
                        background: $background;
                        color: $color-dark;
                        font-size: 16px;
                        padding: 10px 1em;
                        text-transform: none;
                        &:nth-child(n+1) {
                            border-top: 1px solid #ccc;
                        }
                    }
                    &.dropdownOpen {
                        max-height: 300px;
                        opacity: 1;
                    }
                }
            }
        }
        .menuToggleWrapper {
            display: none;
        }
        @media screen and (max-width: 660px) {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: .5em;
            .logo { width: 100%; text-align: center; }
        }
        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
            .navList {
                flex-direction: column;
                position: absolute;
                max-height: 0;
                overflow: hidden;
                width: 100%;
                transition: .5s max-height ease;
                background: #fff;
                top: 100px;
                z-index: 1;
                gap: 0 !important;
                box-shadow: 0 3px 2px rgba($color-dark, .3);
                left: 0;
                &.active {
                    display: flex;
                    max-height: 540px; // UL TOTAL HEIGHT
                }
                li {
                    position: relative;
                    a {
                        display: block;
                        color: #000;
                        padding: 5px 15px;
                        text-align: center;
                        &:hover, &:focus {
                            color: $color-colored;
                        }
                        &.dropdown {
                            &.active::after {
                                transform: rotate(180deg);
                            }
                            &::after {
                                right: unset;
                                top: 20px;
                                margin-left: 10px;
                            }
                        }
                    }
                    .dropdownList {
                        position: relative;
                    }
                }
            }
            .logo {
                width: 50%;
                text-align: center;
                a {
                    display: block;
                    text-align: left;
                }
            }
            .menuToggleWrapper {
                display: block;
            }
        }
        .menuToggleWrapper {
            padding: 10px 0;
            margin-top: -10px;
            z-index: 1;
            .menuToggle {
                display: block;
                margin: auto;
                border: none;
                background: none;
                cursor: pointer;
                width: 18px;
                height: 18px;
                position: relative;
                &::before, &::after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    background: $color-light;
                    left: 0;
                    transition: .3s all;
                }
                &::before {
                    top: 7px;
                }
                &::after {
                    bottom: 0;
                }
                .toggleNav {
                    top: 0;
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    left: 0;
                    background: $color-light;
                    transition: .3s all;
                }
                &:hover {
                    &::before, &::after, .toggleNav {
                        background: $color-colored;
                    }
                }
                &:focus { outline: none; }
                &.active {
                    &::before {
                        transform: rotate(45deg);
                        top: 7px;
                    }
                    &::after {
                        transform: rotate(-45deg);
                        top: 7px;
                    }
                    .toggleNav {
                        opacity: 0;
                    }
                }
            }
        }  
    }
}