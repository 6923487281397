@mixin h-big {
    font-size: 7vw;
    @media screen and (max-width: 1200px) {
        font-size: 8vw;
    }
    @media screen and (max-width: 992px) {
        font-size: 9vw;
    }
    @media screen and (max-width: 768px) {
        font-size: 10vw;
    }
    @media screen and (max-width: 568px) {
        font-size: 12vw;
    }
}

.title {
    font-size: 52px;
    font-weight: 300;
    letter-spacing: .5px;
    color: $color-secondary;
    @media screen and (max-width: 1200px) {
        font-size: 46px;
    }
    @media screen and (max-width: 992px) {
        font-size: 38px;
    }
    @media screen and (max-width: 768px) {
        font-size: 36px;
    }
    @media screen and (max-width: 568px) {
        font-size: 32px;
    }
    &.titleUnderline {
        position: relative;
        padding-bottom: 20px;
        &::after {
            content: '';
            width: 130px;
            height: 1px;
            background: #ccc;
            position: absolute;
            bottom: 0;
            right: calc(50% - 65px);
        }
    }
    &.titleBig {
        font-weight: bold;
    }
}

.subTitle {
    font-size: 32px;
    font-weight: 300;
    letter-spacing: .5px;
    color: $color-secondary;
    @media screen and (max-width: 1200px) {
        font-size: 30px;
    }
    @media screen and (max-width: 992px) {
        font-size: 28px;
    }
    @media screen and (max-width: 768px) {
        font-size: 26px;
    }
    @media screen and (max-width: 568px) {
        font-size: 24px;
    }
    &.titleUnderline {
        position: relative;
        padding-bottom: 20px;
        &::after {
            content: '';
            width: 130px;
            height: 1px;
            background: #ccc;
            position: absolute;
            bottom: 0;
            right: calc(50% - 65px);
        }
    }
    &.titleTenter {
        text-align: center;
    }
    &.titleBig {
        font-weight: bold;
    }
}

.titleCenter {
    text-align: center;
}

.titleDivider {
    margin: 0 !important;
    padding: 10px 0;
    background: $color-secondary;
    color: $color-light;
    text-transform: uppercase;
    position: sticky;
    top: -1px;
    z-index: 3;
}

.sectionDivider {
    margin: 0 !important;
    font-weight: 500;
    padding: 20px 0;
    background: $color-colored;
    color: $color-primary;
}

.hSmall {
    font-size: 18px;
    font-weight: 300;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'Khand' !important;
}