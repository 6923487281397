.btnClose {
    border: none;
    background: none;
    color: #bdc1c6;
    cursor: pointer;
    font-weight: 26px;
    &:focus {
        outline: none;
        color: $color-secondary;
    }
}

.btnSubmit {
    font-family: 'Roboto';
    background: $color-colored-2;
    border: none;
    border-radius: 1px;
    padding: 0 20px;
    font-weight: 500;
    letter-spacing: 1px;
    color: $color-primary;
    transition: .3s all;
    cursor: pointer;
    border: 2px solid transparent;
    &:focus {
        outline: none;
        box-shadow: 0 0 8px 0px $color-colored-2;
    }
    &.disabled {
        background: #dadce0 !important;
        color: rgba($color-secondary, .26) !important;
        cursor: context-menu;
        &:focus {
            color: rgba($color-secondary, .26) !important;
        }
    }
}

.btnDecorated {
    font-family: 'Roboto';
    font-weight: 500;
    text-decoration: none;
    color: $color-primary !important;
    letter-spacing: .3px;
    background: $color-colored-2;
    padding: 10px 20px;
    display: inline-block;
    line-height: 1.5;
    transition: .3s all;
    border: 2px solid transparent;
    &:hover, &:focus {
        background: rgba($color-colored-2, .065);
        color: $color-colored-2 !important;
        border-color: $color-colored-2;
    }
    &:focus {
        outline: none;
    }
}

.btnDecoratedBorder {
    font-weight: 500;
    text-decoration: none;
    color: $color-colored-2;
    letter-spacing: .3px;
    border: 2px solid $color-colored-2;
    padding: 10px 20px;
    line-height: 1.5;
    display: inline-block;
    transition: .3s all;
    &:hover, &:focus {
        background: rgba($color-colored-2, .9);
        border-color: rgba($color-colored-2, .9);
        color: $color-primary;
    }
    &:focus {
        outline: none;
    }
}

.btnDownload {
    cursor: pointer;
    display: block;
    margin: 2em auto;
    padding: 1em 2.5em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.btnMore {
    text-decoration: none;
    font-weight: 700;
    color: $color-colored-2;
    position: relative;
    letter-spacing: .5px;
    padding-bottom: 10px;
    transition: .3s all;
    max-width: max-content;
    &::after {
        content: "\00a0\00a0";
        bottom: 0;
        height: 2px;
        left: 50%;
        position: absolute;
        background: $color-colored-2;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }
    &:hover::after, &:focus::after {
        display: block;
        width: 100%; 
        left: 0; 
    }
}

.btnDisabled {
    pointer-events: none;
}