@import '../../Css/variables';

.root {
    margin-bottom: 4em;
    .seeWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2em;
        .title {
            font-weight: bold;
        }
        .content {
            flex-basis: 400px;
            flex-grow: 1;
            .btnDecoratedBorder {
                margin-right: 2em;
                margin-bottom: 1em;
            }
        }
        .more {
            flex-basis: 400px;
            flex-grow: 1;
            background-image: url('../../assets/see-also.jpg');
            background-position: center;
            background-size: cover;
            color: $color-light;
            padding: 0 2em 1em 2em;
            .title {
                color: $color-light;
            }
        }
        @media screen and (max-width: 860px) {
            .content { order: 2; }
            .more { order: 1; text-align: center; padding: 1em; }
        }
    }
}