@import '../../Css/variables';

.watermark {
    display: flex;
    background: $color-secondary;
    width: 100%;
    height: 100%;
    padding: 1em 0;
    img {
        display: block;
        margin: auto;
    }
}