@import './Css/variables';

* {
    box-sizing: border-box;
    &::selection {
        background: $color-colored;
        color: $color-primary;
    }
}

html {
    width: 100vw;
    overflow-x: hidden;
    scroll-behavior: smooth;
}


body {
    padding: 0; margin: 0;
    font-family: $font-default;
    background: $background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.hideOverflow {
        overflow: hidden;
    }
}

a:focus {
    color: $color-colored;
}
