@import '../../Css/variables';

.title {
    font-weight: bold;
}

.gaugeText {
    font-size: 32px;
    margin: 0;
    font-weight: bold;
}

.scoreDetails {
    padding: 2em 0;
    h3 {
        margin: 0;
    }
    .currentUrl {
        a {
            color: $color-colored;
        }
    }
}

.score {
    position: relative;
    animation: appear 3s;
}

@keyframes appear {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.scoreBox {
    padding: 4em 0;
    &.colored {
        background: $color-light;
    }
    &.render {
        .container {
            margin: auto !important;
            max-width: 1200px !important;
        }
        .scoreLinks {
            display: none !important;
        }
    }
    .container {
        display: flex;
        gap: 3em;
        .scoreBoxContent {
            order: 2;
            flex-basis: 60%;
            .subTitle {
                font-weight: bold;
                margin-bottom: 1em;
                margin-top: 0;
            }
            .scoreBoxList {
                margin-bottom: 1.5em;
            }
            .scoreLinks {
                display: flex;
                flex-wrap: wrap;
                gap: 1em;
                margin-top: 2em;
            }
        }
        .scoreBoxIndicator {
            position: relative;
            order: 1;
            flex-basis: 40%;
            padding: 0 2em;
            align-self: center;
            &.Excellent svg {
                text {
                    fill: $score-well !important;
                }
                path:nth-child(2) {
                    stroke: $score-well;
                }
            } 
            &.Average svg {
                text {
                    fill: $score-average !important;
                }
                path:nth-child(2) {
                    stroke: $score-average;
                }
            } 
            &.Poor svg {
                text {
                    fill: $score-low !important;
                }
                path:nth-child(2) {
                    stroke: $score-low;
                }
            }
            .totalScoreBasic {
                position: absolute;
                top: 50%; left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        @media screen and (max-width: 768px) { 
            flex-wrap: wrap;
            .scoreBoxContent {
                flex-basis: 100%;
                order: 2;
            }
            .subTitle {
                text-align: center;
            }
            .scoreLinks {
                justify-content: center;
            }
            .scoreBoxIndicator {
                margin: auto;
                order: 1;
                flex-basis: 80%;
                max-width: 300px;
            }
        }
    }
}

.expandedInvitation {
    padding-top: 4em;
    .container {
        border: 1px solid #E2E5E6;
        padding: 2em;
    }
    .expandedBody {
        color: $color-dark;
        span {
            font-weight: bold;
        }
        ul {
            padding: 0 0 0 20px;
            margin-top: 5px;
            list-style: none;
            position: relative;
            li {
                &:not(.containsUl)::before {
                    content: '✓';
                    margin-right: 10px;
                    font-weight: bold;
                }

            }
        }
    }
    .btnDecorated {
        display: table;
        margin: auto;
    }
    .subTitle {
        margin-top: 0;
    }
}