@import '../../Css/variables';

.footer {
    padding: 1em 0;
    height: 420px;
    color: $color-light;
    background: #282828;
    margin-top: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 1em;
        text-align: center;
    }
}