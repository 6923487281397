/* khand-300 - latin-ext */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/khand-v9-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/khand-v9-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/khand-v9-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/khand-v9-latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/khand-v9-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/khand-v9-latin-ext-300.svg#Khand') format('svg'); /* Legacy iOS */
}
/* khand-500 - latin-ext */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/khand-v9-latin-ext-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/khand-v9-latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/khand-v9-latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/khand-v9-latin-ext-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/khand-v9-latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/khand-v9-latin-ext-500.svg#Khand') format('svg'); /* Legacy iOS */
}
/* khand-700 - latin-ext */
@font-face {
  font-family: 'Khand';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/khand-v9-latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/khand-v9-latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/khand-v9-latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/khand-v9-latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/khand-v9-latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/khand-v9-latin-ext-700.svg#Khand') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v20-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v20-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-ext-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v20-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-ext-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-latin-ext-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v20-latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-latin-ext-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}