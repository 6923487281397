// container

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
      width: 100%;
  }
  @media (min-width: 992px) {
      width: 970px;
  }
  @media (min-width: 1200px) {
      width: 1170px;
  }
  @media (max-width: 768px) {
      width: 100%;
  }
}
// container small

.containerSmall {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
      width: 670px;
  }
  @media (min-width: 992px) {
      width: 850px;
  }
  @media (min-width: 1200px) {
      width: 970px;
  }
  @media (max-width: 768px) {
      width: 100%;
  }
}

// sr only

.srOnly {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
	clip-path: inset(50%) !important;  /* 2 */
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;            /* 3 */
}

// Small classes

.textCenter {
  text-align: center;
  display: block;
}

// Loading

.xtraSpace {
    margin: 25px auto;
    height: 20px;
    .error {
        text-align: center;
    }
}

.example-enter {
    opacity: .01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.important {
    font-weight: bold;
    color: $color-colored;
}

// LANDING

.expandedLanding {
    padding-top: 120px;
    background: $color-secondary;
}