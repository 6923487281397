@import '../../Css/variables';

.landing {
    background: $color-light;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: $color-light;
    @media screen and (min-width: 769px) {
        background-image: url('../../assets/landing-bg.jpg');
    }
    @media screen and (min-width: 569px) and (max-width: 768px) {
        background-image: url('../../assets/landing-bg-2.jpg');
    }
    @media screen and (max-width: 568px) {
        background-image: url('../../assets/landing-bg-3.jpg');
    }
    .title, .subTitle {
        color: $color-light;
    }
}

.measure {
    padding: 9em 0;
    background: #000000c1;
    @media screen and (max-width: 768px) {
        padding: 6em 0 1em 0;
    }
    .measureResults {
        .measureWrapper {
            padding: 30px;
            text-align: center;
            form {
                width: 70%;
                margin: auto;
                display: flex;
                justify-content: center;
                .btnDecoratedBorder {
                    background: #fff;
                    border-color: #fff;
                    &:hover, &:focus {
                        background: $color-colored-2;
                        border-color: $color-colored-2;
                        color: #fff;
                    }
                    @media screen and (max-width: 768px) {
                        order: 2;
                    }
                }
                @media screen and (max-width: 768px) { width: 100%;}
                .inputField {
                    width: 100%;
                    position: relative;
                    .btnSubmit {
                        letter-spacing: .5px;
                        min-width: 150px;
                    }
                    input[type=text], input[type=url] {
                        background-color: #fff;
                        border-radius: 1px;
                        border: 1px solid #e8eaed;
                        color: #202124;
                        height: 48px;
                        margin: 0;
                        max-width: 100%;
                        outline: none;
                        padding: 8px 16px;
                        transition: box-shadow .2s ease-in-out;
                        width: 100%;
                        font-family: $font-default;
                        font-size: 16px;
                        &:focus ~ span {
                            width: 100%;
                            height: 3px;
                        }
                        &::-webkit-input-placeholder {
                            font-size: 16px;
                        }
                    }
                    span {
                        position: absolute;
                        left: 0;
                        content: '';
                        height: 0;
                        background: $color-colored;
                        transition: all .1s linear;
                        width: 100%;
                        bottom: 0; 
                    }
                    
                    .btnClose {
                        width: auto !important;
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 100%;
                        padding: 0 15px;
                    }
                }
                .btnSubmit {
                    min-width: 150px;
                    letter-spacing: .5px;
                }
                @media screen and (max-width: 460px) {
                    gap: 1em !important;
                    flex-direction: column;
                    .btnSubmit {
                        padding: 15px 0 !important;
                    }
                }
            }
            .scoreCards {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                box-shadow: 0 0 5px 0 $color-dark;
                .scoreCard {
                    flex-grow: 1;
                    min-width: 25%;
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;
                    border-left: 1px solid #dadce0;
                    @media screen and (max-width: 768px) {width: 50%;}
                    .cardTitle {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
            p {
                color: $color-light;
                font-size: .9em;
                font-weight: 400;
            }
        }
        .checkMore {
            margin-top: 3em;
            display: flex;
            gap: 1em;
            justify-content: center;
        }
    }
}