$color-primary: #fff;
$color-secondary: #202124;
$color-colored: #00E57E;
$color-colored-2: #E51F00;

$color-light: #f5fffa;
$color-dark: #737B7D;

$font-default: 'Roboto', 'Segoe UI', arial, -apple-system, BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

$background: #fff;

/* TYPOGRAPHY */

@import './headings';

/* ESSENTIALS */

@import './essential';

/* FONTFACE */

@import './fontface';

/* BUTTONS */

@import './buttons';

// SCORINGS

$score-well: #06BD18;
$score-average: #FFC700;
$score-low: #E31212;

@mixin w-icon {
    display: flex;
    gap: 10px;
    line-height: 1.1;
    &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.Excellent {
    color: $score-well;
    margin-top: 0;
}
.Average {
    color: $score-average;
    margin-top: 0;
}
.Poor {
    color: $score-low;
    margin-top: 0;
}