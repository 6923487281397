@import '../../Css/variables';

.root {
    position: fixed;
    background: $color-colored;
    border: none;
    right: 50px; bottom: 50px;
    width: 40px; height: 40px;
    z-index: 100;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    border-radius: 6px;
    &::before {
        content: '';
        width: 100%; height: 100%;
        background-image: url('../../assets/icons/down-chevron.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transform: rotate(180deg);
    }
    &:hover {
        opacity: 1;
    }
    &:focus {
        outline: none;
    }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}