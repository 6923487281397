@import '../../../Css/variables';

@mixin bg-icon {
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: calc(100% - 60px) center;
}

.root {
    .textCenter {
        margin-top: 0;
    }
    // @media screen and (min-width: 769px) {
    //     &.ExcellentIcon {
    //         @include bg-icon;
    //         background-image: url('../../../assets/icons/tick.svg')
    //     }
    //     &.AverageIcon {
    //         @include bg-icon;
    //         background-image: url('../../../assets/icons/warning.svg')
    //     }
    //     &.PoorIcon {
    //         @include bg-icon;
    //         background-image: url('../../../assets/icons/exclamation.svg')
    //     }
    // }
    @media screen and (max-width: 768px) {
        .Excellent {
            @include w-icon;
            &::before {background-image: url('../../../assets/icons/tick.svg')}
        }
        .Average {
            @include w-icon;
            &::before {background-image: url('../../../assets/icons/warning.svg')}
        }
        .Poor {
            @include w-icon;
            &::before {background-image: url('../../../assets/icons/exclamation.svg')}
        }
    }
    .scoreWrapper {
        display: flex;
    }
    .scoreContent {
        width: 70%;
        padding-right: 2em;
        h2 {
            font-size: 25px;
            font-weight: 300;
        }
        @media screen and (max-width: 769px) {
            width: 100%;
        }
    }
    .scoreSign {
        width: 30%;
        padding: 1em;
        svg {
            width: 100%;
            height: auto;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    &.render {
        h2::before {
            display: none;
        }
        &::before {
            display: none !important;
        }
        .scoreSign {
            display: block !important;
        }
    }
}